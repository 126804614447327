import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ArticleItem from '../../../components/ArticleItem';
import Loader from '../../../components/Loader';
import Empty from '../../../components/Empty';
import { apiHelper } from 'helpers/apiHelper';
import Trans from '../../../helpers/translator';
import { getWindowWidth } from '../../../helpers/util';
import './index.scss';

const GET_SPOT_URL = '/spots?page[number]={number}&page[size]={size}&filter[random]=1';

const PAGE_SIZE = 12;
const MOBILE_PAGE_SIZE = 6;
const DEFAULT_IMAGE_URL = `${process.env.ASSET_URL}shared/img/coming-soon.jpg`;
const MOBILE_MIN_WIDTH = 768;

export default class SpotSuggestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isLoadedAll: false,
      spots: [],
      pageNum: 0,
      pageSize: getWindowWidth() <= MOBILE_MIN_WIDTH ? MOBILE_PAGE_SIZE : PAGE_SIZE,
      resultList: [],
    };
  }

  componentDidMount() {
    this.fetchResult();
  }

  fetchResult = () => {
    const requestUrl = GET_SPOT_URL
      .replace('{place_id}', this.state.placeId)
      .replace('{number}', this.state.pageNum + 1)
      .replace('{size}', this.state.pageSize);

    apiHelper.get(requestUrl).then((response) => {
      console.log(response);

      if (response.data.errors) {
        this.handlerErrors(response.data.errors);
        return;
      }

      this.updateStates({
        spots: this.state.spots.concat(response.data.data),
        pageNum: response.data.meta.current_page,
        loading: false,
        isLoadedAll: response.data.meta.current_page === response.data.meta.last_page,
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  updateStates = (newState, callback) => {
    this.setState(prevState =>
      Object.assign(
        prevState,
        newState,
      ),
    callback,
    );
  };

  handlerErrors = (errors) => {
    for (let i = 0; i < errors.length; i += 1) {
      // TODO: handle elasticseach error
      if (errors[i].status === 701) {
      } else if (errors[i].status === 601) {
      }
    }
  };

  handleLoadMoreClick = () => {
    this.updateStates({
      loading: true,
    },
    this.fetchResult,
    );
  };

  render() {
    return (
      <div className="spot-suggestion-container" >
        <h3>{Trans('spot')}</h3>
        <div className="wrapper">
          {
            this.state.spots.map((spot) =>
              (<ArticleItem
                key={spot.id}
                label={Trans('spot')}
                labelIcon="spot-b"
                imageUrls={spot.attributes.optimize_img_urls}
                name={spot.attributes.name}
                description={spot.attributes.description}
                location={[
                  spot.attributes.region.country_name,
                  spot.attributes.region.name,
                ].join(' | ')}
                link={spot.attributes.link}
              />),
            )
          }
          {
            !this.state.loading && this.state.isLoadedAll &&
            this.state.spots.length === 0 &&
            <Empty text={Trans('no_records')} />
          }
          {
            this.state.loading &&
            <Loader />
          }
        </div>
        {
          !this.state.loading && !this.state.isLoadedAll &&
          <div style={{ width: '100%', textAlign: 'center' }}>
            <button className="btn btn-normal" onClick={this.handleLoadMoreClick} style={{ fontSize: '100%' }}>
              <i className="icon icon-more" />
              <span>{Trans('load_more')}</span>
            </button>
          </div>
        }
      </div>
    );
  }
}

if (document.getElementById('spot-suggestion')) {
  ReactDOM.render(<SpotSuggestion />, document.getElementById('spot-suggestion'));
}
