import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './index.scss';

class Empty extends Component {
  render() {
    return (
      <div className="empty-container">
        <p>{this.props.text}</p>
      </div>
    );
  }
}

Empty.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Empty;
