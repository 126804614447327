import axios, { AxiosRequestConfig } from 'axios';
// temp axios for fixing TRV-73, as the return response structure is different after using json-api-normalizer

declare global {
    interface Window {
        showLoginForm?: () => void;
    }
}

interface MetaElement extends Element {
    content?: string;
}

const axiosConfig: AxiosRequestConfig = {
    baseURL: process.env.API_SERVER,

    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        'X-CSRF-TOKEN': (document.head.querySelector('meta[name="csrf-token"]') as MetaElement)?.content ?? null,
    },
    withCredentials: true,

    validateStatus(status: number) {
        // For unauthorized user, open the login form
        if (status === 401) {
            if (window.showLoginForm) {
                window.showLoginForm();
            }
        }

        return status >= 200 && status < 300; // default
    },
}
export const apiHelper = axios.create(axiosConfig);
