import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImageDisplayer from '../Image/ImageDisplayer';

import './index.scss';

const MAX_WIDTH = 480;

class ArticleItem extends Component {
  state = {
    showMenu: false,
  };

  handleMenuToggle = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  handleFavouriteAdd = () => {
    this.props.onAddToFavourite();
    this.handleMenuToggle();
  };

  handleFavouriteRemove = () => {
    this.props.onRemoveFromFavourite();
    this.handleMenuToggle();
  };

  render() {
    const {
      isHot,
      link,
      imageUrls,
      label,
      labelIcon,
      name,
      description,
      location,
      onSelect,
      isShowFavourite,
      isAddToFavourite,
    } = this.props;

    return (
      <article className={`article-item ${isHot ? 'hot' : ''} ${isShowFavourite && !isAddToFavourite ? 'dim' : ''}`}>
        <div className="article-item-wrapper">
          <a href={link}>
            <div className="content">
              <ImageDisplayer imageUrls={imageUrls} maxWidth={MAX_WIDTH} />
              <div className="details">
                <div className="label">
                  <span className={`icon icon-${labelIcon}`}>{label}</span>
                  <span className="icon icon-location">{location}</span>
                </div>
                <div className="name">{name}</div>
                <div className="description">{description}</div>
              </div>
            </div>
          </a>
          {
            isShowFavourite &&
            <div className="article-menu">
              <span
                className={`icon ${this.state.showMenu ? 'icon-arrow-up' : 'icon-arrow-down'}`}
                onClick={this.handleMenuToggle}
              />
              {
                this.state.showMenu &&
                <ul className="article-menu-dropdown">
                  <li>
                    {
                      isAddToFavourite &&
                      <span className="icon icon-trash" onClick={this.handleFavouriteRemove} />
                    }
                    {
                      !isAddToFavourite &&
                      <span className="icon icon-heart" onClick={this.handleFavouriteAdd} />
                    }
                  </li>
                </ul>
              }
            </div>
          }
        </div>
      </article>
    );
  }
}

ArticleItem.propTypes = {
  link: PropTypes.string.isRequired,
  imageUrls: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  labelIcon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  isHot: PropTypes.bool,
  isShowFavourite: PropTypes.bool,
  isAddToFavourite: PropTypes.bool,
  onAddToFavourite: PropTypes.func,
  onRemoveFromFavourite: PropTypes.func,
};

ArticleItem.defaultProps = {
  isShowFavourite: false,
};

export default ArticleItem;
