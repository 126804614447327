import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './index.scss';

class Loader extends Component {
  render() {
    return (
      <div className="loader-wrapper">
        <div className="loader"><div /><div /><div /><div /></div>
      </div>
    );
  }
}

Loader.propTypes = {
};

export default Loader;
