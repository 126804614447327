export function parseQueryString(url) {
  let match;
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = s => decodeURIComponent(s.replace(pl, ' '));
  const index = url.indexOf('?');
  const query = index !== -1 ? url.substring(index + 1) : '';

  const urlParams = [];
  while (match = search.exec(query)) {
    urlParams.push({
      [decode(match[1])]: decode(match[2]),
    });
  }

  return urlParams;
}

export function convertObjectToQueryString(obj) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }

  return str.join('&');
}

export function convertArrayToQueryString(array) {
  const str = [];
  for (let i = 0; i < array.length; i++) {
    for (const p in array[i]) {
      str.push(`${p}=${encodeURIComponent(array[i][p])}`);
    }
  }

  return str.join('&');
}

export function convertDate(timestamp) {
  const format = process.env.DATETIME_FORMAT;
  const date = new Date(timestamp * 1000);

  return format
    .replace('YYYY', date.getFullYear())
    .replace('MM', (`${date.getMonth() + 1}`).padStart(2, '0'))
    .replace('DD', (`${date.getDate()}`).padStart(2, '0'))
    .replace('HH', (`${date.getHours()}`).padStart(2, '0'))
    .replace('mm', (`${date.getMinutes()}`).padStart(2, '0'));
}

/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
*/
export function mergeDeep(...objects) {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.slice(0).concat(...oVal.slice(0));
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

/**
* Performs a deep clone of objects and returns new object. Does not modify
* objects (immutable)
*
* @param {object} obj - Object to clone
* @returns {object} New object
*/
export function cloneDeep(obj) {
  if (obj === null || typeof obj !== 'object') return obj;
  if (obj instanceof Date) {
    const copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }
  if (obj instanceof Array) {
    const copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = cloneDeep(obj[i]);
    }
    return copy;
  }
  if (obj instanceof Object) {
    const copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = cloneDeep(obj[attr]);
    }
    return copy;
  }

  throw new Error('Unable to clone this object.');
}

export function debounce(func, delay) {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
}

export function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

export function getWindowWidth() {
  return window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
}
