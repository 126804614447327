import PropTypes from 'prop-types';
import React, { Component } from 'react';

const DEFAULT_SQUARE_IMAGE_URL = `${process.env.ASSET_URL}shared/img/coming-soon-square.jpg`;
const DEFAULT_CROP_IMAGE_URL = `${process.env.ASSET_URL}shared/img/coming-soon.jpg`;
const VALID_TYPES = ['crop', 'thumbnail', 'banner'];

const MAX_WIDTH = 1600;

class ImageDisplayer extends Component {
  getDefaultImage = () => {
    const { type } = this.props;
    if (type === 'crop') {
      return DEFAULT_CROP_IMAGE_URL;
    } else if (type === 'thumbnail') {
      return DEFAULT_SQUARE_IMAGE_URL;
    }

    return null;
  };

  render() {
    const { imageUrls, caption, maxWidth } = this.props;
    const sortedKeys = Object.keys(imageUrls)
      .filter(key => key <= maxWidth)
      .sort();

    return (
      <picture>
        { !!sortedKeys.length &&
          sortedKeys.map((key) => {
            const lastItemKey = sortedKeys[sortedKeys.length - 1];
            if (key === lastItemKey) {
              // assign the image with the largest size among the urls as default image
              return (<img key={key} src={imageUrls[key]} alt={caption} />);
            }
            
            return (<source key={key} media={`(max-width: ${key}px)`} srcSet={imageUrls[key]} />);
          })
        }
        { !Object.keys(imageUrls).length &&
          <img src={this.getDefaultImage()} alt={caption} />
        }
      </picture>
    );
  }
}

ImageDisplayer.propTypes = {
  imageUrls: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  caption: PropTypes.string,
  type: PropTypes.oneOf(VALID_TYPES),
  maxWidth: PropTypes.number,
};

ImageDisplayer.defaultProps = {
  caption: '',
  type: 'crop',
  maxWidth: MAX_WIDTH,
};

export default ImageDisplayer;
